//Hero Section
.dot-pattern {
    position: absolute;
    top: 23%;
    left: 8.5%;
    height: 20vh;
    width: 20vh;
}

@media (max-width: 1350px) {
    .dot-pattern {
        display: none;
    }
}

.bg-heading {
    @include x-lg-heading(calc(55px + 1vw), $marginBottom: 2%)
}

.info-text {
    @include regularFont(1.25rem);
    line-height: 1.7;
    margin-bottom: 4%;

    .info-text-break {
        display: block;
    }
}

.hero-img-1 {
    width: 70vh;
}

@media (max-width: 1200px) {
    .bg-heading {
        font-size: calc(53px + 1vw);
    }

    .info-text {
        font-size: 1.1rem;
    }

    .hero-img-1 {
        margin-top: 8%;
    }
}

@media (max-width: 991px) {
    .hero-img-1 {
        width: 80vw;
    }
}

@media (max-width: 405px) {
    .info-text {
        .info-text-break {
            display: inline;
        }
    }
}

.cta-btn {
    @include button(none, $peepBlueColor, rgba(255, 255, 255, 1), 8px);
    height: 50px;
    width: 180px;
    padding: 0;
}


//Modal Section
.md-heading {
    @include lg-heading;
}

.md-info {
    @include body-text($fontSize: 1.25rem, $width: 70%, $margin: 2% auto);
}

.md-link-icon-clover {
    width: fit-content;
    @include button(0.6px solid #F5E900, #F5E900, #000000, 4px);
    font-family: 'Lato', sans-serif;
    font-size: 1.5rem;
    margin: 1% auto 4%;
}

.md-link {
    @include flexDisplay(center);

    .md-link-box {
        margin: 4% 4% 2%;

        .md-link-icon {
            transition: all .2s ease-in-out;

            &:hover {
                transform: scale(1.1);
            }
        }
    }
}

@media (max-width: 1200px) {
    .md-info {
        font-size: 1.1rem;
    }
}

@media (max-width: 991px) {
    .md-info {
        width: 90%;
        margin: 5% auto;
    }

    .md-link-box {

        .md-link-icon {
            width: 90%;
        }
    }
}



//Features Section
.features-section {
    background: $lightColorBackground;
    padding: $largeScreenSectionPadding 0;
    text-align: center;

    .feature-info {
        @include body-text($width: 40%, $margin: 2% auto);
        color: #000000;
    }

    .feature-button {
        width: fit-content;
        @include button(0.6px solid #DB0000, #DB0000, #ffffff, 4px);
        font-family: 'Lato', sans-serif;
        font-size: 1.5rem;
    }

    .feature-order-btn {
        margin: 0 auto 3%;
    }

    .feature-link-icon {
        @include flexDisplay(center);

        .feature-link-icon-box {
            margin: 1% 3% 2%;

            .md-link-icon {
                transition: all .2s ease-in-out;

                &:hover {
                    transform: scale(1.1);
                }
            }
        }
    }

    .feature-icons {
        @include flexDisplay(center);

        .box {
            @include regularFont(1.1rem);
            text-align: center;
            margin: 4% 1% 0;
            width: fit-content;
        }
    }
}

@media (max-width: 991px) {
    .features-section {
        padding: $smallScreenSectionPadding 0;

        .feature-info {
            font-size: 1.1rem;
            width: 80%;
            margin: 5% auto;
        }

        .feature-link-icon {
            .feature-link-icon-box {
                .md-link-icon {
                    width: 90%;
                }
            }
        }

        .feature-icons {
            .box {
                font-size: 1rem;
                width: 90%;

                img {
                    width: 90vw;
                }
            }
        }
    }
}